@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;1,100&display=swap');

$font-primary: 'Montserrat';

html {
  scroll-behavior: smooth;
}

.view-house--carousel {
  background-color: white;
  height: 100%;

  img,
  video {
    width: 100%;
    max-height: 80vh;
  }
}
.bold {
  font-weight: bold;
}
.view-house--modal-title {
  text-align: center;
  width: 100%;
}
.breadcrumb {
  background: none !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.prev-next-icons {
  margin-top: -6rem;
  position: relative;
}
.carousel-prev-icon {
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 28px;
  height: 28px;
  left: 804px;
  top: 690px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  background: #FFF;
}
.carousel-prev-icon.inactive {
  background: #F6F6F7;
}

.house-view--header--div {
  .house-view-details--span {
    color: #202223;
    font-size: 0.85em;
    display: flex;
    align-items: center;
    //styleName: Large Screen/body - reg;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .separator {
    display: inline-block;
    min-height: 5px;
    min-width: 5px;
    border-radius: 50%;
    background-color: #202223;
    margin: 5px 10px;
  }
  hr {
    margin: 20px 0;
  }

  .house-view-details--icons {
    display: none;
    font-size: 1.8em;
    color: #ef6d6c;

    .separator {
      margin: 0 40px;
      background-color: #ef6d6c !important;
    }

    .bedroom-count {
      position: absolute;
      margin-left: 2px;

      span,
      .utils-icons {
        display: flex;
        height: 20px;
        width: 20px;
        background-color: #ef6d6c;
        color: white;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 0.6em;
        font-weight: bolder;
      }
      .utils-icons {
        color: #ef6d6c;
        background-color: white;
      }
    }
  }
}
.browse-more {
  margin-bottom: 6rem;
  margin-top: -4rem;
  text-align: center;
  button {
    background-color: #00A69C;
    border-color: #00A69C;
    width: 7rem;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    padding: 0.7rem;
    &:hover, &:active, &:focus {
      background-color: #00A69C !important;
      border-color: #00A69C !important;
    }
  }
}
.carousel .slide img.view-details-image {
  height: 30rem;
}
.carousel .thumb img.view-details-image {
  height: 5rem;
}
.carousel-root .carousel {
  text-align: center;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border-radius: 0.5rem;
  border: 3px solid #3a8d8c;
}
.carousel .thumb {
  height: 76px;
  width: 90px !important;
  border-radius: 5px !important;
  border: 0px;
  margin: 0px 5px;
  padding: 0px;
}

.people-interested-text, .rent-per-month-text, .rent-fee-ugx-text {
  //styleName: Large Screen/caption;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7175;
}

button.react-share__ShareButton:focus {
  outline: none !important;
}

.view-house--wrapper h3,
.view-house--wrapper h4 {
  color: #202223;
  font-weight: 800 !important;
}

.view-house--wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;

  .view-house--div {
    max-width: 1200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;

    .view-house--header {
      margin-bottom: 20px;

      > div:nth-child(1) {

        h3 {
          padding-bottom: 10px;
          margin-top: 20px;
          font-size: 42px;
          font-style: normal;
          font-weight: 800;
          line-height: 54px;
          letter-spacing: 0px;

          sub {
            font-size: 0.5em;
            font-weight: lighter !important;
            font-style: italic;
            color: #555;
          }
        }

        .view-house-location--meta {
          padding-bottom: 15px;
          color: #ef6d6c;
          background-color: #FFF5F5;
          display: table-row;

          a {
            color: #ef6d6c;
          }

          span {
            padding-left: 3px;
            padding-right: 5px;
            //styleName: Large Screen/sub heading;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            text-transform: uppercase;

          }
        }

        .view-house-location--meta:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .star-rating {
        padding-right: 20px;

        > span {
          font-size: 0.875em;
        }
      }
      h6 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #202223;
      }
    }

    .switch-carousel-content {
      margin-top: 25px;
    }

    .show-all-images,
    .switch-carousel-content {
      align-self: flex-end;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3a8d8c;
      color: white;
      border: none;

      .count {
        display: inline-block;
        margin-left: 5px;
        margin-top: 2px;

        span {
          border: 1px solid white;
          display: flex;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-size: 0.6em;
          font-weight: bolder;
        }
      }
    }

    .switch-carousel-content {
      display: none;
    }
    .show-all-images:active {
      background-color: #3a8d8c;
    }

    .show-all-images:hover{
      .count {
        span {
          border: 1px solid white;
        }
      }
    }

    .display-carousel {
      display: none;
      border-radius: 30px;
      overflow: hidden;
    }

    .view-house--images {
      display: flex;
      align-items: center;
      height: 30em;
      margin-bottom: 2em;
      overflow: hidden;
      border-radius: 30px;

      .view-house-main--img {
        width: 50%;
        height: 100%;
        overflow: hidden;
        margin-right: 0.5em;

        img,
        video {
          cursor: pointer;
          width: 100%;
          height: 100%;
          border-radius: 30px 0px 0px 30px;
          outline: none;
        }
      }

      .full--img {
        width: 100%;
        margin: 0 auto;
        padding: 0px;
        height: auto;

        img,
        video {
          width: 100%;
          height: auto;
        }
      }

      .view-house-img-flex--wrapper {
        width: 50%;
        height: 100%;

        > div:nth-child(1) {
          padding-bottom: 5px;

          div:nth-child(2) {
            border-radius: 0px 30px 0px 0px;
            overflow: hidden;
          }
        }

        > div:nth-child(2) {
          padding-top: 5px;

          div:nth-child(2) {
            border-radius: 0px 0px 30px 0px;
            overflow: hidden;
          }
        }

        .view-house-img-flex {
          display: flex;
          height: 50%;

          > div {
            width: 50%;
          }

          > div:nth-child(1) {
            margin-right: 10px;
          }

          div {
            overflow: hidden;

            img {
              cursor: pointer;
              width: 100%;
              height: 100%;
            }
          }
        }

        .half--img {
          height: 100% !important;
          padding: 0 !important;
          border-radius: 0px 30px 30px 0px;
          overflow: hidden;

          div {
            width: 100%;
            margin: 0 !important;
          }
        }

        .two-images {
          flex-direction: column;
          height: 100%;
          padding: 0 !important;

          div:nth-child(1) {
            border-radius: 0px 30px 0px 0px;
            padding-bottom: 10px;
          }

          div:nth-child(2) {
            border-radius: 0px 0px 30px 0px !important;
            padding-top: 10px;
          }
        }

        .three-images,
        .two-images {
          div {
            width: 100% !important;
            margin: 0 !important;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .three-images {
          div {
            border-radius: 0px 0px 30px 0px;
          }
        }
      }
    }

    .house-view-details--wrapper {
      .house-view-details--div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 32px;
        left: 108px;
        top: 819px;
        background: #F6F6F7;
        border-radius: 7px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;

        .house-view-description {
          line-height: 20px;
          padding: 20px 0;
          h2 {
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;

          }
        }

        .house-rules,
        .amenities {
          line-height: 2em;
          // padding-top: 30px;
          padding-bottom: 10px;

          h4 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
          }
          li {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0px;
            text-align: left;
          }
          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;
          }
        }

        .contacts {
          display: flex;
          align-items: center;
          padding: 25px;

          .profile--img {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            border: 1px solid black;
            overflow: hidden;
            margin-right: 50px;

            img {
              width: auto;
              height: 100%;
            }
          }

          .contacts-details {
            display: flex;
            flex-direction: column;
            line-height: 2em;

            .checked-icon {
              margin-left: 10px;
              font-size: 0.6em;
              color: #3a8d8c;
            }

            span {
              color: #444;
            }

            sub {
              margin-bottom: 20px;
            }
          }
        }
      }

      .reserve-information--wrapper {
        width: 100%;

        > div {
          position: sticky;
          top: 0;
          display: flex;
          align-items: center;
          border-radius: 15px;
          width: 100%;

          .reserve-information {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            table {
              th,
              td {
                padding: 15px 10px;
              }

              tr {
                height: 40px;
              }
            }

            .reserve--btn,
            .submit--btn {
              align-self: center;
              padding: 10px;
              width: 100%;
              background-color: #3a8d8c;
              color: white;
              border: none;
            }

            .reserve--btn {
              margin: 20px 0px;
              display: flex;
              justify-content: center;
            }

            .darken {
              background-color: #777 !important;
            }

            form {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              min-width: 350px;
              width: 100%;

              input {
                padding: 10px;
                text-align: center;
                width: 80%;
                margin-bottom: 20px;
                outline: none;
              }

              .reserve-contact {
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;
              }

              sub {
                color: #ef6d6c;
                font-weight: bolder;
                margin-bottom: 20px;
              }

              .close-edit {
                align-self: flex-end;
                margin-right: 20px;
                font-size: 1.3em;
                color: #444;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .google-map {
      height: 428px;
      width: 95%;
      margin: 50px 0 70px;
      align-self: center;
      margin-bottom: 3rem;
      border-radius: 7px;
      p {
        color: #ef6d6c;
        font-weight: bolder;
        font-size: 2em;
      }
    }
  }
}

.related-listing-text {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

@media only screen and (max-width: 1300px) {
  .house-view-details--wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .house-view-details--div {
      width: 100% !important;

      h3 {
        margin-top: 40px !important;
      }
    }
    .reserve-information--wrapper {
      display: block !important;
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;

      > div {
        margin: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;

        .reserve-information {
          align-items: flex-start !important;
        }

        .reserve--btn {
          align-self: flex-start !important;
          // margin: 25px 10px !important;
        }

        form {
          width: 60% !important;
          margin-top: 10px;

          input {
            width: 100% !important;
            border: 1px solid #444;
            border-radius: 5px;
          }

          .close-edit {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .show-all-images-full {
    margin-top: -5% !important;
  }
}

@media only screen and (max-width: 1120px) {
  .show-all-images-full {
    margin-top: -2% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .view-house--images {
    display: none !important;
  }

  .display-carousel,
  .switch-carousel-content {
    display: block !important;
  }

  .reserve-information--wrapper {
    > div {
      width: 100% !important;

      form {
        width: 100% !important;
      }
    }
  }

  .view-house--header {
    flex-direction: column;

    .star-rating {
      width: 100% !important;
      align-items: flex-start !important;
      margin-bottom: 10px;

      > span {
        margin-top: 15px !important;
      }
    }
  }
  .view-house--wrapper .view-house--div .show-all-images,
  .view-house--wrapper .view-house--div .switch-carousel-content {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .house-view-details--span {
    display: none !important;
  }

  .house-view-details--icons {
    display: flex !important;
    align-items: center;
  }

  .reserve-information--wrapper {
    form {
      min-width: 300px !important;
    }
  }
  .show-all-images-full {
    margin-top: 3% !important;
  }
  .related-listing-text {
    padding: 0px 15px;
  }
  .carousel .slide img.view-details-image {
    height: auto;
    max-height: 30rem;
  }
  .image-and-details-wrapper h3,
  .image-and-details-wrapper h4 {
    font-size: 1.1rem !important;
  }
  .browse-more {
    margin-bottom: 1rem;
    text-align: center;
    margin-top: -1rem;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .house-view-details--span {
    display: none !important;
  }

  .house-view-details--icons {
    display: flex !important;
    align-items: center;
  }
  
  }

@media only screen and (max-width: 700px) {
  .house-view-details--icons {
    font-size: 1.8em !important;
  }
  .carousel-inner {
    margin-bottom: 0rem;
  }
  .reserve-information--wrapper {
    width: 100% !important;
  }
  .image-and-details-wrapper {
    .carousel-root{
      width: 100%;
    }
   }
   .house-details-loader {
     min-height: 63vh;
   }
}

@media only screen and (max-width: 520px) {
  .house-view-details--icons {
    font-size: 1.3em !important;
  }
  .bedroom-count {
    > span,
    svg {
      height: 18px !important;
      width: 18px !important;
    }
  }
  .separator {
    margin: 0 30px !important;
    background-color: #ef6d6c !important;
  }
  .reserve-information--wrapper {
    > div {
      padding: 0px !important;
    }
  }
}

@media only screen and (max-width: 300px) {
  .reserve-information--wrapper {
    > div {
      min-height: 300px;
    }
  }
}

$a-tag-color: #212529;

a {
  color: $a-tag-color;
  &:hover {
    color: $a-tag-color;
  }
}
