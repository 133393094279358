.house-container-image {
  height: 14.3rem;
  box-sizing: border-box;
  object-fit: cover;
}

button > a, button > a:hover {
  color: #ffffff;
}

.houses-text {
  width: inherit;
  padding: 3rem 0 3rem 0;
}

.house-details-type, .house-details-location {
  opacity: 0.8;
}


.house-details-type {
  max-height: 2.2rem;
  overflow: auto;
}

.status-button {
  background-color: #009A69;
}
.status-button-darker {
  background-color: #BABEC3;
}

.house-details-specific-text {
  opacity: 0.6;
  display: block;
  white-space:nowrap;
}

.houses-list{
  justify-content: center;
}

.houses-list .status-button {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.houses-list .status-button-darker {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .carousel-inner {
    height: inherit;
  }
  .houses-list {
    display: contents;
  }
  .houses-text {
    width: inherit;
    align-items: center;
    padding: 1rem;
  }
  .carousel .control-dots {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .houses-text {
    width: inherit;
    align-items: center;
    padding: 2rem 1rem 2rem 1rem;
  }
  .site-name {
    flex: 0 0 50%;
  }
}

.items-container {
  padding-left: 0;
  padding-right: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhoneInputInput {
  border: none;
}
