.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-content {
  display: flex;
  flex-direction: column;
  background: transparent;
  min-height: 100vh;
  position: relative;
  /* padding-bottom: 1em; */
}

.site-content .site-header {
  background: white;
  /* box-shadow: 0 1px 4px rgba(0,21,41,.08); */
  z-index: 1;
  position: fixed;
  right: 0;
  left: 0;
  height: 6rem;
}
.site-content .site-header.nav-shadow {
  box-shadow: 0px 8px 24px rgb(0 0 0 / 10%), 0px 2px 2px rgb(0 0 0 / 10%);
}

.site-content .page-wrapper {
  padding: 0 2rem 0;
  overflow-x: hidden;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 5rem;
  margin-top: 5rem;
  min-height: calc(100vh - 120px);
}
.rentals-logo-footer-text {
  color: #00A69C;
  padding-left: 0.2rem;
  margin-left: -1rem;
}
.rentals-logo-footer {
  height: 1.2rem;
  width: 2rem;
  margin-bottom: 0.2rem;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 768px){
  .footer{
    position: relative;
  }
  .site-content .site-header {
    padding: 0px;
  }
  .site-content .page-wrapper {
    margin-bottom: 0px;
  }
  .footer-dashes {
    display:-webkit-box;
  }
  .site-content {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 769px){
  .site-content .page-wrapper {
    margin-bottom: 10rem;
  }
}
